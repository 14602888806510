var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form-model',{ref:"form",staticClass:"activity-periods",attrs:{"model":_vm.form}},[_c('label',{staticClass:"activity-periods__label"},[_c('span',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("* ")]),_vm._v("Activity periods")]),_c('a-tooltip',{attrs:{"placement":"top","arrow-point-at-center":"","overlayStyle":{ 'white-space': 'normal', 'min-width': '240px' },"get-popup-container":_vm.getPopupContainer}},[(!!_vm.disabledAddActivityPeriodText)?_c('template',{slot:"title"},[_c('span',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.disabledAddActivityPeriodText))])]):_vm._e(),_c('span',{staticClass:"add-btn",class:{'add-btn__disabled' : _vm.disabledAddActivityPeriod || _vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.addActivityPeriod.apply(null, arguments)}}},[_vm._v(" + Add ")])],2)],1),_c('div',{staticClass:"activity-periods__periods"},_vm._l((_vm.form.activityPeriods),function(period,index){return _c('div',{key:index,staticClass:"activity-periods__dates"},[_c('span',{staticClass:"activity-periods__number",class:{'activity-periods__number__visible' : _vm.form.activityPeriods.length > 1}},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"activity-periods__dates__container"},[_c('a-form-model-item',{ref:`start-period-${index}`,refInFor:true,attrs:{"prop":`activityPeriods.${index}.startDate`,"colon":false,"rules":[
            {
              required: true,
              validator: _vm.validateCallback,
              message: () => _vm.startDateMessageError,
              trigger: ['change'],
            },
          ]}},[_c('a-date-picker',{class:{ 'activity-periods__expired-period' : _vm.form.activityPeriods[index].endDate?.isBefore() },attrs:{"disabled":_vm.loading,"show-time":{
              defaultValue: _vm.$moment('00:00:00', 'HH:mm'),
              format: 'HH:mm'
            },"disabled-date":_vm.disabledStartDate,"format":"DD-MM-YY HH:mm","size":"large","placeholder":"Start date"},on:{"focus":function($event){return _vm.setDisabledStartDateValue(index)},"change":function($event){return _vm.setDisabledStartDateValue(index)}},model:{value:(_vm.form.activityPeriods[index].startDate),callback:function ($$v) {_vm.$set(_vm.form.activityPeriods[index], "startDate", $$v)},expression:"form.activityPeriods[index].startDate"}})],1),_c('span',{staticClass:"activity-periods__hyphen"},[_vm._v("—")]),_c('a-form-model-item',{ref:`end-period-${index}`,refInFor:true,attrs:{"prop":`activityPeriods.${index}.endDate`,"colon":false,"rules":[
            {
              required: true,
              validator: _vm.validateCallback,
              message: () => _vm.endDateMessageError,
              trigger: ['change'],
            },
          ]}},[_c('a-date-picker',{staticClass:"hover-date-picker",class:{ 'activity-periods__expired-period' : _vm.form.activityPeriods[index].endDate?.isBefore() },attrs:{"show-time":{
              defaultValue: _vm.$moment('00:00:00', 'HH:mm'),
              format: 'HH:mm'
            },"disabled-date":_vm.disabledEndDate,"format":"DD-MM-YY HH:mm","size":"large","placeholder":"End date","disabled":_vm.loading},on:{"focus":function($event){return _vm.setDisabledEndDateValue(index)}},model:{value:(_vm.form.activityPeriods[index].endDate),callback:function ($$v) {_vm.$set(_vm.form.activityPeriods[index], "endDate", $$v)},expression:"form.activityPeriods[index].endDate"}})],1),(_vm.form.activityPeriods.length !== 1)?_c('div',{staticClass:"activity-periods__icon"},[_c('a-icon',{staticClass:"font-size-18 text-danger delete-icon",attrs:{"type":"delete"},on:{"click":function($event){return _vm.removePeriod(index)}}})],1):_vm._e()],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }