<template>
  <a-form-model :model="form" ref="form" class="activity-periods">
    <label class="activity-periods__label">
      <span><span style="color: red;">* </span>Activity periods</span>
      <a-tooltip
        placement="top"
        arrow-point-at-center
        :overlayStyle="{ 'white-space': 'normal', 'min-width': '240px' }"
        :get-popup-container="getPopupContainer"
      >
        <template v-if="!!disabledAddActivityPeriodText" slot="title">
          <span class="font-weight-light">{{ disabledAddActivityPeriodText }}</span>
        </template>
        <span
          class="add-btn"
          :class="{'add-btn__disabled' : disabledAddActivityPeriod || loading}"
          @click.stop="addActivityPeriod"
        >
          + Add
        </span>
      </a-tooltip>
    </label>
    <div class="activity-periods__periods">
      <div
        v-for="(period, index) in form.activityPeriods"
        :key="index"
        class="activity-periods__dates"
      >
        <span
          class="activity-periods__number"
          :class="{'activity-periods__number__visible' : form.activityPeriods.length > 1}"
        >
          {{ index + 1 }}
        </span>
        <div class="activity-periods__dates__container">
          <a-form-model-item
            :prop="`activityPeriods.${index}.startDate`"
            :ref="`start-period-${index}`"
            :colon="false"
            :rules="[
              {
                required: true,
                validator: validateCallback,
                message: () => startDateMessageError,
                trigger: ['change'],
              },
            ]"
          >
          <!-- validator: validateCallback(index, 'startDate'), -->
            <a-date-picker
              v-model="form.activityPeriods[index].startDate"
              :disabled="loading"
              :show-time="{
                defaultValue: $moment('00:00:00', 'HH:mm'),
                format: 'HH:mm'
              }"
              :disabled-date="disabledStartDate"
              format="DD-MM-YY HH:mm"
              size="large"
              placeholder="Start date"
              :class="{ 'activity-periods__expired-period' : form.activityPeriods[index].endDate?.isBefore() }"
              @focus="setDisabledStartDateValue(index)"
              @change="setDisabledStartDateValue(index)"
            />
          </a-form-model-item>
          <span class="activity-periods__hyphen">—</span>
          <a-form-model-item
            :prop="`activityPeriods.${index}.endDate`"
            :ref="`end-period-${index}`"
            :colon="false"
            :rules="[
              {
                required: true,
                validator: validateCallback,
                message: () => endDateMessageError,
                trigger: ['change'],
              },
            ]"
          >
            <a-date-picker
              v-model="form.activityPeriods[index].endDate"
              :show-time="{
                defaultValue: $moment('00:00:00', 'HH:mm'),
                format: 'HH:mm'
              }"
              :disabled-date="disabledEndDate"
              format="DD-MM-YY HH:mm"
              size="large"
              placeholder="End date"
              :disabled="loading"
              class="hover-date-picker"
              :class="{ 'activity-periods__expired-period' : form.activityPeriods[index].endDate?.isBefore() }"
              @focus="setDisabledEndDateValue(index)"
            />
          </a-form-model-item>
          <div v-if="form.activityPeriods.length !== 1" class="activity-periods__icon">
            <a-icon class="font-size-18 text-danger delete-icon" type="delete" @click="removePeriod(index)" />
          </div>
        </div>
      </div>
    </div>
  </a-form-model>
</template>

<script>

export default {
  props: {
    periods: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      activityPeriods: [],
    },
    previosDate: null,
    endDate: null,
    startDateMessageError: '',
    endDateMessageError: '',
  }),
  watch: {
    periods: {
      handler(val, oldVal) {
        if (val?.length && !oldVal?.length) {
          this.setPeriods()
        }
        if (!val?.length && !oldVal?.length) {
          this.addDefaultPeriod()
        }
      },
      deep: true,
    },
  },
  computed: {
    disabledAddActivityPeriod() {
      return (this.form.activityPeriods?.length && (!this.form.activityPeriods[this.form.activityPeriods.length - 1].startDate || !this.form.activityPeriods[this.form.activityPeriods.length - 1].endDate)) || this.form.activityPeriods?.length === 10 || this.loading
    },
    disabledAddActivityPeriodText() {
      if (this.form.activityPeriods?.length && (!this.form.activityPeriods[this.form.activityPeriods.length - 1].startDate || !this.form.activityPeriods[this.form.activityPeriods.length - 1].endDate)) {
        if (!this.form.activityPeriods[this.form.activityPeriods.length - 1].startDate && !this.form.activityPeriods[this.form.activityPeriods.length - 1].endDate) {
          return 'To add a new range, the previous one must have a start and end date'
        } else if (!this.form.activityPeriods[this.form.activityPeriods.length - 1].endDate) {
          return 'To add a new range, the previous one must have an end date'
        } else if (!this.form.activityPeriods[this.form.activityPeriods.length - 1].startDate) {
          return 'To add a new range, the previous one must have a start date'
        }
      }
      if (this.form.activityPeriods?.length === 10) {
        return 'You have reached the maximum number of activity periods'
      }
      return ''
    },
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement
    },
    range(start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    },
    validateCallback(validator) {
      const index = Number(validator.field.split('.')[1])
      const target = validator.field.split('.')[2]
      this.startDateMessageError = ''
      this.endDateMessageError = ''

      if (!this.form.activityPeriods[index][target]) {
        if (target === 'startDate') {
          this.startDateMessageError = 'Start date is required'
        } else {
          this.endDateMessageError = 'End date is required'
        }
        return false
      }

      if (target === 'startDate') {
        if (index > 0) {
          if (this.form.activityPeriods[index - 1].endDate) {
            if (!this.form.activityPeriods[index][target]?.isAfter(this.form.activityPeriods[index - 1].endDate)) {
              this.startDateMessageError = 'Start date must be after previous end'
              return false
            }
          }
          if (this.form.activityPeriods[index].endDate) {
            if (!this.form.activityPeriods[index][target]?.isBefore(this.form.activityPeriods[index].endDate)) {
              this.startDateMessageError = 'Start must be before end'
              return false
            }
          }
          return true
        } else {
          if (this.form.activityPeriods[index].endDate) {
            if (!this.form.activityPeriods[index][target]?.isBefore(this.form.activityPeriods[index].endDate)) {
              this.startDateMessageError = 'Start must be before end'
              return false
            }
            return true
          }
        }
      } else {
        if (index > 0) {
          if (this.form.activityPeriods[index + 1]?.startDate) {
            if (!this.form.activityPeriods[index][target]?.isBefore(this.form.activityPeriods[index + 1].startDate)) {
              this.endDateMessageError = 'End must be after next start'
              return false
            }
          }
          if (this.form.activityPeriods[index].startDate) {
            if (!this.form.activityPeriods[index][target]?.isAfter(this.form.activityPeriods[index].startDate)) {
              this.endDateMessageError = 'End date must be after start date'
              return false
            }
          }
          return true
        } else {
          if (this.form.activityPeriods[index].startDate) {
            if (!this.form.activityPeriods[index][target]?.isAfter(this.form.activityPeriods[index].startDate)) {
              this.startDateMessageError = 'End date must be after start date'
              return false
            }
            return true
          }
        }
      }

      return true
    },
    setPeriods() {
      this.periods.forEach(period => {
        this.form.activityPeriods.push({
          startDate: period?.starts_at ? this.$moment(period.starts_at) : undefined,
          endDate: period.ends_at ? this.$moment(period.ends_at) : undefined,
        })
      })
    },
    getPeriods() {
      return this.form.activityPeriods.map(period => {
        return {
          starts_at: period?.startDate ? period.startDate.clone().utc(false).format('YYYY-MM-DD HH:mm') : null,
          ends_at: period.endDate ? period.endDate.clone().utc(false).format('YYYY-MM-DD HH:mm') : null,
        }
      })
    },
    addActivityPeriod() {
      if (this.disabledAddActivityPeriod || this.loading) {
        return
      }
      this.addDefaultPeriod()
    },
    addDefaultPeriod() {
      this.form.activityPeriods.push({
        startDate: undefined,
        endDate: undefined,
      })
    },
    setDisabledStartDateValue(index) {
      this.clearValidate()
      if (index > 0) {
        if (this.form.activityPeriods[index - 1]?.endDate) {
          this.previosDate = this.form.activityPeriods[index - 1].endDate?.clone()
        } else {
          this.previosDate = null
          let currentIndex = JSON.parse(JSON.stringify(index))
          do {
            currentIndex -= 1
            if (this.form.activityPeriods[currentIndex].endDate || this.form.activityPeriods[currentIndex].startDate) {
              if (this.form.activityPeriods[currentIndex].endDate) {
                this.previosDate = this.form.activityPeriods[currentIndex].endDate?.clone()
                break
              } else {
                this.previosDate = this.form.activityPeriods[currentIndex].startDate?.clone()
                break
              }
            }
          } while (currentIndex > 0)
        }

        if (this.form.activityPeriods[index].endDate) {
          this.endDate = this.form.activityPeriods[index].endDate?.clone()
        } else {
          this.endDate = null
          if (index !== this.form.activityPeriods?.length - 1) {
            let currentIndex = JSON.parse(JSON.stringify(index))
            do {
              currentIndex += 1
              if (this.form.activityPeriods[currentIndex].endDate || this.form.activityPeriods[currentIndex].startDate) {
                if (this.form.activityPeriods[currentIndex].startDate) {
                  this.endDate = this.form.activityPeriods[currentIndex].startDate?.clone()
                  break
                } else {
                  this.endDate = this.form.activityPeriods[currentIndex].endDate?.clone()
                  break
                }
              }
            } while (currentIndex > this.form.activityPeriods.length - 1)
          }
        }
      } else {
        this.previosDate = null
        if (this.form.activityPeriods[index].endDate) {
          this.endDate = this.form.activityPeriods[index].endDate.clone()
        } else {
          this.endDate = null
        }
      }
    },
    setDisabledEndDateValue(index) {
      this.clearValidate()
      if (index > 0) {
        if (this.form.activityPeriods[index].startDate) {
          this.previosDate = this.form.activityPeriods[index].startDate?.clone()
        } else {
          this.previosDate = null
          let currentIndex = JSON.parse(JSON.stringify(index))
          do {
            currentIndex -= 1
            if (this.form.activityPeriods[currentIndex].endDate || this.form.activityPeriods[currentIndex].startDate) {
              if (this.form.activityPeriods[currentIndex].endDate) {
                this.previosDate = this.form.activityPeriods[currentIndex].endDate?.clone()
                break
              } else {
                this.previosDate = this.form.activityPeriods[currentIndex].startDate?.clone()
                break
              }
            }
          } while (currentIndex > 0)
        }

        if (index !== this.form.activityPeriods.length - 1 && this.form.activityPeriods[index + 1]?.startDate) {
          this.endDate = this.form.activityPeriods[index + 1].startDate?.clone()
        } else {
          this.endDate = null
          if (index !== this.form.activityPeriods?.length - 1) {
            let currentIndex = JSON.parse(JSON.stringify(index))
            do {
              currentIndex += 1
              if (this.form.activityPeriods[currentIndex].endDate || this.form.activityPeriods[currentIndex].startDate) {
                if (this.form.activityPeriods[currentIndex].startDate) {
                  this.endDate = this.form.activityPeriods[currentIndex].startDate?.clone()
                  break
                } else {
                  this.endDate = this.form.activityPeriods[currentIndex].endDate?.clone()
                  break
                }
              }
            } while (currentIndex > this.form.activityPeriods.length - 1)
          }
        }
      } else {
        if (this.form.activityPeriods[index].startDate) {
          this.previosDate = this.form.activityPeriods[index].startDate.clone()
        } else {
          this.previosDate = null
        }

        if (this.form.activityPeriods[index + 1]?.startDate) {
          this.endDate = this.form.activityPeriods[index + 1].startDate?.clone()
        } else {
          this.endDate = null
        }
      }
    },
    disabledStartDate(day) {
      const startOfTheDay = day.clone().startOf('day')
      if (this.endDate && this.previosDate) {
        return startOfTheDay.valueOf() >= this.endDate.clone().endOf('day').valueOf() || startOfTheDay.clone().endOf('day').valueOf() <= this.previosDate.clone().startOf('day').valueOf()
      } else if (this.endDate) {
        return startOfTheDay.valueOf() >= this.endDate.clone().endOf('day').valueOf()
      } else if (this.previosDate) {
        return startOfTheDay.clone().endOf('day').valueOf() <= this.previosDate.clone().startOf('day').valueOf()
      }
      return false
    },
    disabledEndDate(day) {
      const startOfTheDay = day.clone().endOf('day')
      if (this.previosDate && this.endDate) {
        return startOfTheDay.valueOf() <= this.previosDate.clone().startOf('day').valueOf() || startOfTheDay.clone().startOf('day').valueOf() >= this.endDate.clone().endOf('day').valueOf()
      } else if (this.previosDate) {
        return startOfTheDay.valueOf() <= this.previosDate.clone().startOf('day').valueOf()
      } else if (this.endDate) {
        return startOfTheDay.clone().startOf('day').valueOf() >= this.endDate.clone().endOf('day').valueOf()
      }
      return false
    },
    removePeriod(index) {
      this.form.activityPeriods.splice(index, 1)
    },
    async validate() {
      if (!this.form.activityPeriods?.length) {
        return true
      }

      let isValid = true

      for (let i = 0; i < this.form.activityPeriods.length; i++) {
        await this.$refs.form.validateField(`activityPeriods.${i}.startDate`, async (errorMessage) => {
          if (errorMessage) {
            isValid = false
          }
        })
        await this.$refs.form.validateField(`activityPeriods.${i}.endDate`, async (errorMessage) => {
          if (errorMessage) {
            isValid = false
          }
        })
      }

      if (this.form.activityPeriods?.length && !this.form.activityPeriods[this.form.activityPeriods.length - 1].startDate && !this.form.activityPeriods[this.form.activityPeriods.length - 1].endDate) {
        this.$notification.error({
          message: 'Activity periods are incorrectly filled',
        })
        isValid = false
      }
      return isValid
    },
    clearValidate() {
      for (let i = 0; i < this.form.activityPeriods.length; i++) {
        this.$refs[`start-period-${i}`][0].clearValidate()
        this.$refs[`end-period-${i}`][0].clearValidate()
      }
    },
  },
  mounted() {
    if (!this.$route.params?.id) {
      this.addDefaultPeriod()
    }
  },
}
</script>

<style lang="scss">
.activity-periods {
  display: flex;
  flex-direction: column;
  gap: 5px;
  &__label {
    display: flex;
    gap: 10px;
    font-weight: bold;
    color: #262626;
    font-size: 14px;
  }
  &__periods {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  &__dates {
    display: flex !important;
    &__container {
      display: flex;
      gap: 10px;
    }
  }
  &__number {
    width: 0;
    opacity: 0;
    visibility: hidden;
    transition: width .3s ease;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    font-size: 16px;
    &__visible {
      opacity: 1;
      visibility: visible;
      width: 10px;
      margin-right: 10px;
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    align-self: flex-end;
  }
  &__hyphen {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
  }
  &__expired-period {
    & input {
      background-color: #dadada !important;
      opacity: .5;
    }
  }
}
.activity-periods .ant-form-explain {
  position: absolute !important;
  white-space: nowrap !important;
}
</style>
